// import { AuthProvider } from '@redwoodjs/auth'
// import { createClient } from '@supabase/supabase-js'
import ReactDOM from 'react-dom'

import { FatalErrorBoundary } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

import './index.css'

// const supabaseClient = createClient(
//     process.env.SUPABASE_URL,
//     process.env.SUPABASE_KEY
//   )

ReactDOM.render(
  <FatalErrorBoundary page={FatalErrorPage}>
    {/* <AuthProvider client={supabaseClient} type="supabase"> */}
      <RedwoodApolloProvider>
        <Routes />
      </RedwoodApolloProvider>
    {/* </AuthProvider> */}
  </FatalErrorBoundary>,
  document.getElementById('redwood-app')
)
