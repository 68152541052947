// This page will be rendered when an error makes it all the way to the top of the
// application without being handled by a Javascript catch statement or React error
// boundary.
//
// You can modify this page as you wish, but it is important to keep things simple to
// avoid the possibility that it will cause its own error. If it does, Redwood will
// still render a generic error page, but your users will prefer something a bit more
// thoughtful. =)

export default () => (
  <main className="flex items-center font-sans text-center bg-gray-200 h-screen">
    <section className="bg-white rounded-lg w-96 p-4 m-o m-auto shadow">
      <h1 className="text-xl m-0 font-medium leading-1 text-red-800">
        <span className="text-red-600">Something went wrong</span>
      </h1>
    </section>
  </main>
)
